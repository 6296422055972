import { ActionTypes } from "../../constants/actionTypes";

const initialState = {
  inversionista: {},
  propiedades: [],
};

export const pidFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_NEWPIDFORM_INVERSIONISTA:
      return { ...state, inversionista: payload };
    case ActionTypes.SET_NEWPIDFORM_PROPIEDADES:
      return { ...state, propiedades: payload };

    default:
      return state;
  }
};
//JSON CON DATA

// const initialState = {
//   inversionista: {
//     rut: "111111111",
//     nombre: "JAVIER",
//     paterno: "GONZALEZ",
//     materno: "PEREZ",
//     direccion: "LOS PELADOS",
//     numero: "32424",
//     email: "MAMSDJD@AK.CL",
//     movil: "5693938292",
//     datos_bancarios: [
//       {
//         banco: "SANTANDER",
//         tipo_cuenta: "CORRIENTE",
//         num_cuenta: "35456452334",
//         email: "JSDJDMLS@GMAIL.COM",
//       },
//       {
//         banco: "BCI",
//         tipo_cuenta: "CORRIENTE",
//         num_cuenta: "1231231",
//         email: "KJSDCNJSKNFS@SDF.CL",
//       },
//     ],

//     propiedades: [
//       {
//         id_proyecto: "3",
//         tipo_propiedad: "departamento",
//         estado: "nueva",
//         fecha_hora_entrega: "2023/03/02 13:00",
//         arrendatario: true,
//         corredora: false,
//         unidades: [
//           {
//             pid: "PID12302",
//             numero: "1123",
//             tipologia: "1D2B",
//             m2: "55",
//             estacionamiento: [
//               { EID: "EID00001", value: "37" },
//               { EID: "EID00002", value: "12B" },
//             ],
//             bodega: [
//               { BID: "BID00003", value: "256" },
//               { BID: "BID00004", value: "324" },
//             ],
//             orientacion: "Nor-Oriente",
//             caracteristicas: [
//               "Barras cortinas",
//               "Luces instaladas",
//               "Horno",
//               "Cocina americana",
//               "Refrigerador",
//             ],
//             descripcion: "Aqui va la descripcion de la propiedad",
//             contribuciones: true,
//             aseo: true,
//             servicios: [
//               {
//                 servicio: "agua",
//                 proveedor: "Aguas Andinas",
//                 id_cliente: "12312345",
//               },
//               {
//                 servicio: "luz",
//                 proveedor: "Chilectra",
//                 id_cliente: "345565673",
//               },
//               {
//                 servicio: "gas",
//                 proveedor: "Gasco",
//                 id_cliente: "789453457",
//               },
//             ],
//             arriendo: {
//               canon: 300000,
//               id_cuenta_bancaria: 1,
//               cobertura: 1,
//             },
//             documentos: [
//               {
//                 id: 1,
//                 titulo: "Mandato",
//                 link: "http://ssdawweasdasfdasdfwedads",
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// };
