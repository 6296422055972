import { ActionTypes } from "../../constants/actionTypes";

// ** Initial State
const initialState = {
  requestsList: {},
  idRequest: {},
  requestsDocList: {},
  showDoc: {},
};

export const rentRequestsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_RENTREQUESTS_SELECTED:
      return { ...state, idRequest: payload };
    case ActionTypes.SET_RENTREQUESTS_LIST:
      return { ...state, requestsList: payload };
    case ActionTypes.SET_RENTREQUESTS_DOCLIST:
      return { ...state, requestsDocList: payload };
    case ActionTypes.SET_RENTREQUESTS_SHOWDOC:
      return { ...state, showDoc: payload };

    default:
      return state;
  }
};

// export default pidListReducer;
