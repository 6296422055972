// src/reducers/rootReducer.js

// ** Redux Imports
import {combineReducers} from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import {pidListReducer, selectedPidReducer} from "./propertyBillControl";
import {pidFormReducer} from "./newPidForm";
import {newInvestorFormReducer} from "./newInvestorForm";
import {rentRequestsReducer} from "./rentRequests";
import {propertyBillsReducer} from "./propertyBills";

import pidArray from "../../views/Pages/PropertyBillControl_v3/store/reducer"; //que paso aqui!!! jajaja hay q corregirlo
import selectedTextReducer from "../slices/selectedTextSlice"; // Importar el nuevo reducer

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  pidListReducer,
  selectedPidReducer,
  pidArray,
  pidFormReducer,
  newInvestorFormReducer,
  rentRequestsReducer,
  propertyBillsReducer,
  selectedText: selectedTextReducer, // Agregar el nuevo reducer
});

export default rootReducer;
