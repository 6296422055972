// src/redux/slices/selectedTextSlice.js
import {createSlice} from "@reduxjs/toolkit";

export const selectedTextSlice = createSlice({
  name: "selectedText",
  initialState: {
    text: "",
  },
  reducers: {
    setSelectedText: (state, action) => {
      state.text = action.payload;
    },
    clearSelectedText: (state) => {
      state.text = "";
    },
  },
});

export const {setSelectedText, clearSelectedText} = selectedTextSlice.actions;

export default selectedTextSlice.reducer;
