import {ActionTypes} from "../../constants/actionTypes";

// ** Initial State
const initialState = {
  PIDList: [],
  CardsData: [],
  // PIDLIstFilter: {},
};

export const pidListReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.SET_PIDLIST:
      return {...state, PIDList: payload};
    case ActionTypes.SET_CARDSDATA:
      return {...state, CardsData: payload};
    // case ActionTypes.SET_PIDLISTFILTER:
    //   return { ...state, PIDLIstFilter: payload };

    default:
      return state;
  }
};

export const selectedPidReducer = (state = {}, {type, payload}) => {
  switch (type) {
    case ActionTypes.SELECTED_PID:
      return {...state, ...payload};
    default:
      return state;
  }
};
// export default pidListReducer;
