export const ActionTypes = {
  SET_PIDLIST: "SET_PIDLIST",
  // SET_PIDLISTFILTER: "SET_PIDLISTFILTER",
  SET_CARDSDATA: "SET_CARDSDATA",
  SELECTED_PID: "SELECTED_PID",
  REMOVE_SELECTED_PID: "REMOVE_SELECTED_PID",

  SET_NEWINVESTORFORM_INVERSIONISTA: "SET_NEWINVESTORFORM_INVERSIONISTA",
  SET_NEWINVESTORFORM_CTASBANCARIAS: "SET_NEWINVESTORFORM_CTASBANCARIAS",
  SET_NEWINVESTORFORM_RUT: "SET_NEWINVESTORFORM_RUT",
  SET_NEWINVESTORFORM_NOMBRE: "SET_NEWINVESTORFORM_NOMBRE",
  SET_NEWINVESTORFORM_APELLIDO_PATERNO: "SET_NEWINVESTORFORM_APELLIDO_PATERNO",
  SET_NEWINVESTORFORM_APELLIDO_MATERNO: "SET_NEWINVESTORFORM_APELLIDO_MATERNO",
  SET_NEWINVESTORFORM_GENERO: "SET_NEWINVESTORFORM_GENERO",
  SET_NEWINVESTORFORM_DIRECCION: "SET_NEWINVESTORFORM_DIRECCION",

  SET_NEWPIDFORM_INVERSIONISTA: "SET_NEWPIDFORM_INVERSIONISTA",
  SET_NEWPIDFORM_PROPIEDADES: "SET_NEWPIDFORM_PROPIEDADES",

  SET_RENTREQUESTS_SELECTED: "SET_RENTREQUESTS_SELECTED",
  SET_RENTREQUESTS_LIST: "SET_RENTREQUESTS_LIST",
  SET_RENTREQUESTS_DOCLIST: "SET_RENTREQUESTS_DOCLIST",
  SET_RENTREQUESTS_SHOWDOC: "SET_RENTREQUESTS_SHOWDOC",

  SET_PROPERTYBILLS_PID: "SET_PROPERTYBILLS_PID",
};
