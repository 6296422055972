// ** Logo
// import logo from "@src/assets/images/logo/logo.png";
import logo from "./logoSpinner2.png";
import logo2 from "./logoSpinner2__backoffice.png";
import logo3 from "./logoSpinner3.png";

import "./KnockitSpinner.scss";

const KnockitSpinner = (area) => {
  let selectLogo = null;
  console.log("KnockitSpinner area", area);
  switch (area.area) {
    case "back":
      selectLogo = logo2;
      break;

    default:
      selectLogo = logo3;
      break;
  }
  return (
    <div className="knockit-spinner-component">
      <div className="knockit-spinner-container">
        <div className="knockit-spinner">
          <div className="knockit-logo-container">
            <img
              className="knockit-logo"
              src={selectLogo}
              // style={{ width: "10%", marginButton: "50%" }}
              alt="logo"
            />
          </div>
          <div className="knockit-loading-flex">
            <div className="knockit-loading-A">
              <div className="effect-1 effects"></div>
              <div className="effect-2 effects"></div>
              <div className="effect-3 effects"></div>
            </div>
            <div className="knockit-loading-B">
              <div className="effect-1 effects"></div>
              <div className="effect-2 effects"></div>
              <div className="effect-3 effects"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnockitSpinner;
