import { ActionTypes } from "../../constants/actionTypes";

// ** Initial State
const initialState = {
  selectedPID: {},
};

export const propertyBillsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_PROPERTYBILLS_PID:
      return { ...state, selectedPID: payload };

    default:
      return state;
  }
};

// export default pidListReducer;
