const initialState = {
  pids: [],
  params: {},
  totalPid: 0,
};

const pidArray = (state = initialState, action) => {
  console.log("acttion", action);
  switch (action.type) {
    case "GET_PID":
      return {
        ...state,
        pids: action.data,
        params: action.params,
        totalPid: action.total,
      };
    default:
      return state;
  }
};

export default pidArray;
